<template>
  <div class="num-transform">
  <ContactInfo></ContactInfo>
    <img
      class="animate__animated"
      v-scroll="'animate__fadeIn'"
      src="@assets/images/NumTransform1.png"
      alt=""
      width="100%"
    />
    <div class="number1">
      <img src="@assets/images/SystemBuild2.png" alt="" width="100%" />
      <div class="contain">
        <div class="Rem0_4 animate__animated" v-scroll="'animate__fadeInDown'">
          <p style="color: white; font-weight: normal !important">服务介绍</p>
        </div>
        <p
          class="Rem0_24 animate__animated"
          v-scroll="'animate__fadeIn'"
          style="animation-delay: 1s;text-indent: 2em;"
        >
          景翔已有近二十年的历史，主要从事企业管理咨询和培训业务，近些年，致力于数字化管理体系的建设，是企业数字化转型升级的先行者，是目前唯一的管理体系数字化转型咨询服务提供商。经多年的实践出版了《管理体系数字化转型实践》专著，为服务顾客提供了一套方法论
        </p>
        <div class="Rem0_4 animate__animated" v-scroll="'animate__fadeInDown'">
          <p style="text-align: start; color: white;font-weight: normal !important">
            服务内容：
          </p>
        </div>
        <div class="ul">
          <span
            class="Rem0_24 animate__animated"
            v-scroll="'animate__fadeInLeftBig'"
            style="animation-delay: 1s"
            v-for="(item, index) in serveList.list1"
            :key="index"
          >
            {{ item }}</span
          >
        </div>
        <div class="ul">
          <span
            class="Rem0_24 animate__animated"
            v-scroll="'animate__fadeInRightBig'"
            style="animation-delay: 1s"
            v-for="(item, index) in serveList.list2"
            :key="index"
          >
            {{ item }}</span
          >
        </div>
        <div class="ul">
          <span
            class="Rem0_24 animate__animated"
            v-scroll="'animate__fadeInLeftBig'"
            style="animation-delay: 1s; white-space: pre-wrap"
            v-for="(item, index) in serveList.list3"
            :key="index"
          >
            {{ item }}</span
          >
        </div>
        <div class="ul">
          <span
            class="Rem0_24 animate__animated"
            v-scroll="'animate__fadeInRightBig'"
            style="animation-delay: 1s; white-space: pre-wrap"
            v-for="(item, index) in serveList.list4"
            :key="index"
          >
            {{ item }}</span
          >
        </div>
      </div>
    </div>
    <div class="number2">
      <img src="../../../assets/images/NumTransform2.png" alt="" />
      <div class="contain">
        <div
          style="animation-delay: 1.5s"
          class="Rem0_4 animate__animated"
          v-scroll="'animate__fadeInDown'"
        >
          <p>管理体系的特点</p>
          <p></p>
        </div>
        <div
          class="box animate__animated"
          style="animation-delay: 1s"
          v-scroll="'animate__fadeIn'"
        >
          <div>
            <p class="Rem0_25"><span></span>渐进式转型</p>
            <p class="Rem0_19">
              渐进式的体系数字化再造，针对企业当前实际需要进行专项设计，见效后再进行下一专项的设计，变大工程为小工程，大大降低了转型风险。
            </p>
          </div>
          <div>
            <p class="Rem0_25"><span></span>价值链驱动</p>
            <p class="Rem0_19">
              以价值驱动的数字化转型，首次提出以新型价值为转型切入点，构建新型能力管理体系。实践证明，此方法能为企业大幅降本增效。
            </p>
          </div>
          <div>
            <p class="Rem0_25"><span></span>专业工具支持</p>
            <p class="Rem0_19">
              景翔有自己开发的数字化工具、软件和独创的方法，它们是景翔多年从事管理咨询的经验累积，易操作，见效快。
            </p>
          </div>
          <div>
            <p class="Rem0_25"><span></span>敏捷体系</p>
            <p class="Rem0_19">
              文件是网络和落地一体化的设计，颠覆了传统的文件表达方式，可分拆可合并，能将原来的体系大幅瘦身，是真正的敏捷体系和无纸化体系。
            </p>
          </div>
          <div>
            <p class="Rem0_25"><span></span>低成本高成效</p>
            <p class="Rem0_19">
              数字化方法使双方的工作量大为减少，反而让咨询服务费用倒退20年，相比同行市场费用可节省至少三倍。
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="number3">
      <div>
        <img
          class="text animate__animated"
          v-scroll="'animate__fadeInLeftBig'"
          src="@assets/images/NumTransform6.png"
          alt=""
        />
        <div
          class="text animate__animated"
          v-scroll="'animate__fadeInRightBig'"
        >
          <div class="Rem0_4">
            <p style="color: #4375c8">数字化管理体系平台</p>
            <p></p>
          </div>
          <p class="Rem0_25">
            搭建了围绕战略目标的数字化管理体系平台该平台是云、网、数、智、运一体化的平台是信息平台、技术平台、AI平台等的一个综合体。
          </p>
          <button class="CustomButton" @click="$router.push('./trial-use')">免费试用</button>
        </div>
      </div>
    </div>
    <div class="number4">
      <div class="contain">
        <div class="Rem0_4 animate__animated" v-scroll="'animate__bounceInUp'">
          <p style="color: #4375c8">我司特有的新型价值链模型</p>
          <p></p>
        </div>
        <p
          class="p1 Rem0_25 animate__animated"
          v-scroll="'animate__bounceInUp'"
        >
          新型价值是链是为顾客打造的一条从市场始端到交付端创造价值的全过程路线图，它综合了波特价值链思想国际公认的过程方法、丰田的精益价值流、价值网络分析技术以及数字化技术的一种创新型工具，可以为企业大幅缩短设计、采购、生产交付等周期。可以为企业带来“可视化”管理地图，显著提升企业的竞争力；为企业管理体系的数字化转型打下坚实的基础。
        </p>
        <img
          class="img1 animate__animated"
          v-scroll="'animate__bounceInUp'"
          src="@assets/images/NumTransform7.png"
          alt=""
        />
        <div class="bottom-box">
          <p class="Rem0_30">新型价值链原理：</p>
          <img
            class="lineimg"
            src="../../../assets/images/ProcessOptimize21.png"
            alt
          />
          <div>
            <p
              class="Rem0_25 animate__animated"
              v-scroll="'animate__fadeInLeftBig'"
            >
              完成了职能三级分解和优化后，从中识别出“增值活动”“辅助活动”，并基于下图原理创建出价值链，即将垂直的职能结构转变为扁平的大流程结构，同时完成核心业务的集成。这是“过程方法”的创新性应用。
            </p>
            <img
              class="animate__animated"
              v-scroll="'animate__fadeInRightBig'"
              src="../../../assets/images/NumTransform3.png"
              alt
            />
          </div>
        </div>
      </div>
    </div>
    <div class="number5">
      <div class="contain">
        <div class="Rem0_4">
          <p style="color: #4375c8">体系数字化转型进化</p>
          <p></p>
        </div>
        <div>
          <div
            class="text animate__animated"
            v-scroll="'animate__fadeInRightBig'"
          >
            <p class="Rem0_25">
              数字化转型的目的是智能化，下图是数字化转型进化图，企业可从中识别自己所处的水平，不同的水平数字化的方式是不同的。
            </p>
            <button class="CustomButton" @click="$router.push('./trial-use')">免费试用</button>
          </div>
          <img
            class="text animate__animated"
            v-scroll="'animate__fadeInLeftBig'"
            src="@assets/images/NumTransform4.png"
            alt=""
          />
        </div>
      </div>
    </div>
    <div class="number4">
      <div class="contain">
        <div class="Rem0_4 animate__animated" v-scroll="'animate__bounceInUp'">
          <p style="color: #4375c8">基于过程方法和战略的数字化转型模型</p>
          <p></p>
        </div>
        <p
          class="p1 Rem0_25 animate__animated"
          v-scroll="'animate__bounceInUp'"
        >
          根据国家出台的标准《数字化转型
          价值效益参考模型》，数字化转型的根本任务是价值体系优化、创新和重构。见下面的原理图
        </p>
        <img
          class="img2 animate__animated"
          v-scroll="'animate__bounceInUp'"
          src="@assets/images/NumTransform5.png"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
import ContactInfo from "../../../components/public/ContactInfo.vue";
export default {
  components: { ContactInfo },
  data() {
    return {
      serveList: {
        list1: [
          "构建经营路线图",
          "部门职能分解",
          "构建新型价值链",
          "职能型向流程型转化",
          "体系结构扁平化",
          "构建体系信息流",
        ],
        list2: [
          "核心业务集成",
          "流程数字化优化",
          "构建体系树",
          "能力点设计",
          "构建目标指标体系",
          "构建绩效标准",
        ],
        list3: [
          "管理体系文件分类",
          "流程要素数字化",
          "表单数字化",
          "构建数据链",
          "体系文件碎片化",
          "构建体系落地\n检查题库",
        ],
        list4: [
          "构建流程标准组件",
          "体系柔性化",
          "管理体系地代码构建",
          "流程项目化",
          "构建智能制造体系",
          "智能制造体系\n成熟度评估",
        ],
      },
    };
  },
};
</script>

<style lang="less" scoped>
.num-transform {
  @media screen and (min-width: 1200px) and (max-width: 1519px) {
    .number1 {
      img {
        height: 10rem !important;
      }
      .Rem0_4 {
        padding-top: 20px !important;
      }
    }
  }
  @media screen and (min-width: 768px) and (max-width: 1199px) {
    .number1 {
      .Rem0_4 {
        padding-top: 0px !important;
      }
      .ul span {
        margin: 5px 2px !important;
      }
    }
  }
  @media screen and (min-width: 320px) and (max-width: 767px) {
    .number1 {
      .Rem0_4 {
        padding-top: 0px !important;
      }
      .ul span {
        margin: 3px 2px !important;
      }
    }
  }
  .contain {
    width: 80%;
    margin: 0 10%;
    .Rem0_4 {
      text-align: center;
      color: #4375c8;
      p:nth-of-type(2) {
        left: 50%;
        transform: translate(-50%, -50%);
        margin-top: 3%;
      }
    }
  }
  .number1 {
    position: relative;
    height: 100vh;
    img {
      position: absolute;
      height: 9rem;
    }
    .contain {
      width: 80%;
      margin: 0 10%;
      position: absolute;
      top: 0;
      color: white;
      text-align: start;
      .Rem0_4:nth-of-type(1) {
        margin: 3% 0 2% 0;
        display: flex;
        justify-content: center;
      }
      .Rem0_4:nth-of-type(2) {
        margin: 2.5% 0 0.5% 0 !important;
      }
      .ul {
        width: 100%;
        display: flex;
        justify-content: space-between;
        opacity: 0.83;
        span {
          display: flex;
          align-items: center;
          justify-content: center;
          flex: 1;
          border: 1px solid;
          text-align: center;
          border: 1px solid #909090;
          border-radius: 5px;
          margin: 0 6px;
          margin-top: 20px;
          background: linear-gradient(to right, #555974, #5d616f);
          cursor: default;
          height: 1rem;
          line-height: 0.3rem !important;
        }
      }
    }
  }
  .number2 {
    height: 8rem;

    img {
      position: absolute;
      width: 100%;
    }
    .contain {
      position: absolute;
      .Rem0_4 {
        margin: 4% 0;
      }
      .box {
        display: flex;
        flex-wrap: wrap;
        padding-top: 1.5%;
        div:hover {
          background: #437ed4;
          p {
            color: white !important;
          }
          span {
            background: white !important;
          }
        }
        div {
          width: 32%;
          margin: 1% 0.6%;
          background: white;
          box-shadow: 0px 1px 4px 2px #d1d7e0;
          border-radius: 15px;
          padding-bottom: 0.2rem;
          p:nth-of-type(1) {
            color: #4375c8;
            margin: 0.15rem 0.35rem 0 0.35rem;
            display: flex;
            align-items: center;
            font-weight: bold;
            line-height: 0.5rem;
            span {
              display: inline-block;
              width: 0.05rem;
              height: 0.26rem;
              background: #4375c8;
              border-radius: 1px;
              margin-right: 0.1rem;
              border-radius: 5px;
            }
          }
          p:nth-of-type(2) {
            line-height: 0.3rem !important;
            margin: 0 0.23rem 0 0.5rem;
            color: #666666;
          }
        }
      }
    }
  }
  .number3 {
    background: #f7fafd;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    > div {
      width: 80%;
      display: inline-flex;
      img {
        width: 55%;
        height: 55%;
      }
      > div {
        padding-left: 30px;
        padding-top: 40px;
        text-align: start;
        .Rem0_25 {
          margin-top: 17%;
          width: 80%;
        }
      }
    }
    .CustomButton {
      margin-top: 1rem;
    }
  }
  .number4 {
    background: #ffffff;
    text-align: center;
    .Rem0_4 {
      padding-top: 4%;
    }
    .p1 {
      margin-top: 5%;
      text-align: start;
    }
    .img1 {
      width: 80%;
      margin-top: 3%;
    }
    .img2 {
      width: 74%;
      margin-top: 2.5%;
      margin-bottom: 2.5%;
    }
    .bottom-box {
      text-align: start;
      margin-top: 3%;
      padding-bottom: 4%;
      .Rem0_30 {
        color: #4375c8;
        font-weight: bold;
      }
      .lineimg {
        width: 0.5rem !important;
      }
      div {
        display: flex;
        align-items: center;
        .Rem0_25 {
          margin-right: 2%;
        }
        img {
          width: 50%;
        }
      }
    }
  }
  .number5 {
    background: #f7fafd;
    .contain {
      padding-bottom: 5%;
      .Rem0_4 {
        padding: 5% 0;
      }
      div:nth-of-type(2) {
        padding: 5% 0 0 0;
        text-align: start;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 65%;
        }
        .CustomButton {
          margin-top: 0.8rem;
        }
      }
    }
  }
}
</style>